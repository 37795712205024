import React, { useState, useEffect } from "react"

export const TestComponent = () => {
  const [data, setData] = useState<string>('')

  useEffect(() => {
    fetch('/data')
      .then(result => result.json())
      .then(json => {
        setData(JSON.stringify(json))
      })
  })

  const getData = async () => {
    const resp = await fetch('/data')

    setData(JSON.stringify(await resp.json()))
  }

  return <div>
    <button onClick={getData}>Data</button>
    <div id="data">{data}</div>
  </div>
}

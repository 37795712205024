import React from 'react';
import { TestComponent } from './content';

function App() {
  return (
    <div className="App">
      <TestComponent/>
    </div>
  );
}

export default App;
